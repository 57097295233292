import { types } from "./type";


export const ToggleNav = (val) => (dispatch) => {
    dispatch({
        type: types.TOGGLE_NAV,
        toggleCollapsedNav: val
      });
};
export const changePageNo = ( page_no ) => {
  return {
      type: types.PAGE_NO,
      page_no: page_no
  };
};

export const changeProfile=(logo)=>{
  return{
    type:types.LOGO,
    logo:logo
  }
}